import { PUBLIC_CONTENTFUL_ENVIRONMENT } from '$env/static/public';
import type { IInitiatives } from "$lib/utils/contentful/types";
import type { InitiativeResults } from './types';

/**
 * 
 * https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters
 * 
 **/
const conditions: { [key: string]: string } = {
  '==': '',               // Equality operator
  '!=': '[ne]',           // Inequality operator
  'in': '[in]',           // Inclusion
  'not in': '[nin]',      // Exclusion
  'exists': '[exists]',   // Existence
  '<': '[lt]',            // Less than.
  '<=': '[lte]',          // Less than or equal to.
  '>': '[gt]',            // Greater than.
  '>=': '[gte]',          // Greater than or equal to.
}

/**
 * Get the event utils object
 * @param _fetch The fetch function
 * @returns The event utils object
 */
export const useEventUtils = (_fetch: {
  (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
  (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
}): EventUtils => {
  return new EventUtils(_fetch);
};

export class EventUtils {
  private static BASE_URI = 'https://cdn.contentful.com';
  private static SPACE_ID = 'fumyn2q16o4i';
  private static ACCESS_TOKEN = 'TRsNY4r-LgKqoW_EwZyjh0wI2Go0WxpIuV5xlsTpdHg';
  private static ENVIRONMENT_ID = PUBLIC_CONTENTFUL_ENVIRONMENT;
  private static CONTENT_TYPE = 'initiatives';

  private static PREVIEW_BASE_URI = 'https://preview.contentful.com';
  private static PREVIEW_ACCESS_TOKEN = 'NkKR_ZRrbcUcvvbuz3iXe8ZRpjp-PtV2Kvs7pQB9qx4';

  private _fetch: {
    (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
    (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
  }

  public constructor(fetch: {
    (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
    (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
  }) {
    this._fetch = fetch;
  }

  private fetchInitiative = async (url: string): Promise<IInitiatives | null> => {
    try {
      const res = await this._fetch(url)
      if (res.ok) {
        console.info(`Initiative at ${url} found.`);
        return await res.json();
      }

      console.warn(`[!] Initiative at ${url} not found.`, await res.text());

      return null;
    } catch (e) {
      console.warn(`[!] Initiative at ${url} not found.`, e);
      return null;
    }
  }

  public getInitiativeBySlug = async (
    slug: string,
    preview: boolean = false
  ): Promise<IInitiatives | null> => {
    if (preview) {
      const query = `${EventUtils.PREVIEW_BASE_URI}/spaces/${EventUtils.SPACE_ID}/environments/${EventUtils.ENVIRONMENT_ID}/entries?access_token=${EventUtils.PREVIEW_ACCESS_TOKEN}&content_type=${EventUtils.CONTENT_TYPE}&fields.slug=${slug}`;

      const rawCollection = await this._fetch(query).then((res) => res.json());

      if (rawCollection.items.length !== 0) {
        const entriesCollection = EventUtils.resolveLinks(rawCollection);
        return entriesCollection.items[0];
      } else {
        return null;
      }
    } else {
      return this.fetchInitiative(`/api/v1/initiatives/sl/${slug}.json`);
    }
  };

  public getInitiativeById = async (
    id: string
  ): Promise<IInitiatives | null> => {
    return this.fetchInitiative(`/api/v1/initiatives/id/${id}.json`);
  };

  public static initiativeTypeEvent = (type: string) => {
    return type == 'Evento Fisico' ? 'Evento in presenza' : type;
  };

  public getNextInitiatives = async (nextLimit: number, nextSkip: number, filters: string[] = [], order: string | null = null) => {
    let nextQuery = `${EventUtils.BASE_URI}/spaces/${EventUtils.SPACE_ID}/environments/${EventUtils.ENVIRONMENT_ID}/entries?access_token=${EventUtils.ACCESS_TOKEN}&content_type=${EventUtils.CONTENT_TYPE}&limit=${nextLimit}&skip=${nextSkip}`;

    if (order) {
      nextQuery += `&order=${order}`;
    }

    if (filters.length !== 0) {
      nextQuery += `&fields.type[in]=${filters.join(',')}`;
    }

    var nextRawCollection = await this._fetch(nextQuery).then((res) => res.json());

    return nextRawCollection;
  };

  public getCloserInitiatives = async (
    page: number = 0,
    pageSize: number = 20,
    filters: string[] = []
  ): Promise<InitiativeResults> => {
    const { initiatives, total } = await this.getInitiatives(
      page,
      pageSize,
      filters,
      'fields.date',
      [
        {
          field: 'date',
          condition: '>=',
          value: new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString()
        },
        {
          field: 'hideInitiativeFromWebsiteAndSearch',
          condition: '!=',
          value: 'true'
        }
      ]
    );

    return { initiatives, total };
  };

  public getPastInitiatives = async (
    page: number = 0,
    pageSize: number = 20,
    filters: string[] = []
  ): Promise<InitiativeResults> => {
    const { initiatives, total } = await this.getInitiatives(
      page,
      pageSize,
      filters,
      '-fields.date',
      [
        {
          field: 'date',
          condition: '<',
          value: new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString()
        },
        {
          field: 'hideInitiativeFromWebsiteAndSearch',
          condition: '!=',
          value: 'true'
        }
      ]
    );

    return { initiatives, total };
  };

  public getInitiatives = async (
    page: number = 0,
    pageSize: number = 20,
    filters: string[] = [],
    order: string | null = null,
    customFilters: {
      field: string,
      condition: string,
      value: string
    }[] = []
  ): Promise<InitiativeResults> => {
    const limit = pageSize;
    const skip = page * pageSize;

    // TODO: Add filters, remove direct contentful fetch and use static API

    let query = `${EventUtils.BASE_URI}/spaces/${EventUtils.SPACE_ID}/environments/${EventUtils.ENVIRONMENT_ID}/entries?access_token=${EventUtils.ACCESS_TOKEN}&content_type=${EventUtils.CONTENT_TYPE}&limit=${limit}&skip=${skip}`;

    if (order) {
      query += `&order=${order}`;
    }

    if (filters.length !== 0) {
      query += `&fields.type[in]=${filters.join(',')}`;
    }

    if (customFilters.length !== 0) {
      customFilters.forEach(filter => {
        query += `&fields.${filter.field}${conditions[filter.condition]}=${filter.value}`;
      });
    }

    var rawCollection = await this._fetch(query).then((res) => res.json());

    var entriesCollection = EventUtils.resolveLinks(rawCollection);
    var initiatives = [...entriesCollection.items];

    return {
      initiatives: initiatives,
      total: entriesCollection.total,
    };
  };

  private static resolveLinks(entriesCollection) {
    const copy = { ...entriesCollection };

    if (entriesCollection.includes && entriesCollection.includes.Asset) {
      const assets = entriesCollection.includes.Asset;
      copy.items.forEach((item) => {
        const fields = item.fields;
        for (const field in fields) {
          if (fields[field].sys && fields[field].sys.linkType === 'Asset') {
            const linkId = fields[field].sys.id;
            const linkedAsset = assets.find((asset) => asset.sys.id === linkId);
            fields[field] = { ...linkedAsset };
          }
        }
      });
      delete copy.includes;
      return copy;
    }
    return entriesCollection;
  }
}
